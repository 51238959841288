import { mapGetters } from "vuex"

const is_seller_dashboard_section = {
    computed: {
        ...mapGetters(["entity_records"]),
        listing_websites () {
            if(!this.listing || !this.listing.id) return []
            const today = new Date()
            return this.entity_records("listing_website").filter(listing_website => {
                return (listing_website.listing_id === this.listing.id) && !!listing_website.publish_on && this.$dayjs(listing_website.publish_on).isSameOrBefore(today, "day")
            })
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
}

export default is_seller_dashboard_section
