<template>
    <listing-view-hero
        :listing="listing"
    >
        <template v-slot:layer>
            <slot></slot>
        </template>
        <div>
            <h5>{{ translate("Welcome {name}!", { name: this.profile.first_name }) }}</h5>
            <h1>{{ listing.computed_display_title }}</h1>
            <div class="columns">
                <h4 class="column col-auto">
                    {{ (listing.computed_price / 100) | nibnut.listing_price(listing.price_frequency) }}
                </h4>
                <h4 class="column col-auto">
                    <span
                        v-for="listing_website in listing_websites"
                        :key="listing_website.id"
                    >
                        <base-link
                            v-if="!!listing_website.url"
                            :href="listing_website.url"
                            :title="translate('View on {name}', { name: listing_website.website_info.name })"
                            target="_blank"
                            class="ml-2"
                            @click.stop
                        >
                            <img
                                :src="listing_website.website_info.logo"
                                :alt="translate('View on {name}', { name: listing_website.website_info.name })"
                                loading="lazy"
                                decoding="async"
                            />
                        </base-link>
                        <img
                            v-else
                            :src="listing_website.website_info.logo"
                            :alt="translate('View on {name}', { name: listing_website.website_info.name })"
                            loading="lazy"
                            decoding="async"
                        />
                    </span>
                </h4>
            </div>
            <documents-list
                :documents="ordered_documents"
                class="mt-3"
            />
        </div>
    </listing-view-hero>
</template>

<script>
import orderBy from "lodash/orderBy"

import { handles_documents, addl_profile_utilities } from "@/custom/mixins"
import is_seller_dashboard_section from "./IsSellerDashboardSection"

import {
    BaseLink
} from "@/nibnut/components"
import { ListingViewHero, DocumentsList } from "@/custom/components"

export default {
    name: "SellerHero",
    mixins: [addl_profile_utilities, handles_documents, is_seller_dashboard_section],
    components: {
        BaseLink,
        ListingViewHero,
        DocumentsList
    },
    mounted () {
        this.reload()
    },
    watch: {
        profile_id: "reload",
        $route: "reload",
        "listing.id": "reload",
        active: "reload"
    },
    methods: {
        reload () {
            if(this.profile_id && !!this.listing && !!this.listing.uuid && this.active) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: this.entity,
                        id: this.listing.uuid,
                        query: {
                            relation_ids: this.relation_ids,
                            fields: [
                                "fieldset::seller-dashboard",
                                "ns::listing_showing;fieldset::seller-dashboard",
                                "ns::listing_feedback;fieldset::seller-dashboard",
                                "ns::listing_email;fieldset::seller-dashboard",
                                "ns::listing_action;fieldset::seller-dashboard"
                            ]
                        }
                    }
                ).catch(error => {
                    this.$error(error.message)
                })
            }
        }
    },
    computed: {
        ordered_documents () {
            if(!this.listing || !this.listing.id) return []
            return orderBy(
                this.owner_documents("App\\Listing", this.listing.id),
                "name",
                "asc"
            )
        }
    },
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "listing",
            entityName: "listing:::listings",
            relation_ids: ["listing_showing", "listing_feedback", "listing_email", "listing_action", "attachment"]
        }
    }
}
</script>
