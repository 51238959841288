<template>
    <div v-if="!!profile_id">
        <system-statistics />

        <realtor-statistics
            :advanced-query="state.advanced_query"
        />

        <dashboard-filter-controls
            title=""
            glyph=""
            class="filters"
        >
            <div class="columns">
                <div class="column col-3 col-md-6 col-sm-12">
                    <form-multi-select
                        id="property_types"
                        name="types"
                        :value="state.advanced_query.types"
                        :options="property_types"
                        :label="translate('Any Property Types')"
                        display="chips"
                        :compact-selection="compact_selection_label(property_types, state.advanced_query.types, '{n} types')"
                        :required="false"
                        :can-toggle-all="true"
                        @input="set_advanced_query_state_values({ types: $event })"
                    />
                </div>
                <div class="column col-3 col-md-6 col-sm-12">
                    <form-multi-select
                        id="neighborhoods"
                        name="neighborhoods"
                        :value="state.advanced_query.neighborhoods"
                        :options="neighborhoods"
                        :label="translate('Any Area')"
                        display="chips"
                        :compact-selection="compact_selection_label(neighborhoods, state.advanced_query.neighborhoods, '{n} areas', '{n} minor areas')"
                        :required="false"
                        :can-toggle-all="false"
                        @input="set_advanced_query_state_values({ neighborhoods: $event })"
                    />
                </div>
                <div class="column col-3 col-md-6 col-sm-12">
                    <form-date-range-input
                        v-if="!!state.advanced_query.availability_changed_on"
                        id="availability_changed_on"
                        name="availability_changed_on"
                        :value="{ ...state.advanced_query.availability_changed_on }"
                        :max="today"
                        :required="false"
                        @input="set_date_range"
                    />
                </div>
                <div class="column col-3 col-md-6 col-sm-12">
                    <div class="columns">
                        <div class="column col-6">
                            <form-input
                                id="min-price"
                                name="min_price"
                                :value="state.advanced_query.min_price / 100"
                                type="number"
                                glyph="dollar-sign"
                                glyph-position="left"
                                :min="0"
                                :number-step="10000"
                                :empty-value="null"
                                :placeholder="translate('No Min.')"
                                :required="false"
                                @input="set_advanced_query_state_values({ min_price: $event * 100 })"
                            />
                        </div>
                        <div class="column col-6">
                            <form-input
                                id="max-price"
                                name="max_price"
                                :value="state.advanced_query.max_price / 100"
                                type="number"
                                glyph="dollar-sign"
                                glyph-position="left"
                                :min="0"
                                :number-step="10000"
                                :empty-value="null"
                                :placeholder="translate('No Max.')"
                                :required="false"
                                @input="set_advanced_query_state_values({ max_price: $event * 100 })"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <dashboard-state-description
                :state="state"
                class="text-center text-gray text-small mt-2"
            />
        </dashboard-filter-controls>

        <div
            :class="{ active: (!!data_version && loading) }"
            class="dashboard-panels"
        >
            <realtor-chance-of-selling
                v-if="ready"
                :advanced-query="state.advanced_query"
            />

            <inventory-statistics
                :advanced-query="state.advanced_query"
            />

            <sales
                :global-state="state"
            />

            <activity-statistics
                :advanced-query="state.advanced_query"
            />

            <loader
                size="lg"
                class="main-loader"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { is_remote_data_table_source } from "@/nibnut/mixins"
import { addl_profile_utilities, addl_misc_utilities, addl_string_utilities } from "@/custom/mixins"

import { FormInput } from "@/nibnut/components"
import { FormMultiSelect, FormDateRangeInput, Loader } from "@/custom/components"
import SystemStatistics from "./SystemStatistics"
import RealtorStatistics from "./RealtorStatistics"
import DashboardFilterControls from "./DashboardFilterControls"
import DashboardStateDescription from "./DashboardStateDescription"
import InventoryStatistics from "./InventoryStatistics"
import ActivityStatistics from "./ActivityStatistics"
import Sales from "./Sales"
import RealtorChanceOfSelling from "./RealtorChanceOfSelling"

export default {
    name: "RealtorDashboard",
    mixins: [is_remote_data_table_source, addl_profile_utilities, addl_misc_utilities, addl_string_utilities],
    components: {
        SystemStatistics,
        RealtorStatistics,
        FormMultiSelect,
        FormDateRangeInput,
        Loader,
        FormInput,
        DashboardFilterControls,
        DashboardStateDescription,
        InventoryStatistics,
        ActivityStatistics,
        Sales,
        RealtorChanceOfSelling
    },
    mounted () {
        this.reroot()
    },
    watch: {
        $route: "reroot",
        profile_id: "reroot",
        "state.advanced_query.availability_changed_on": "maybe_refresh",
        "state.advanced_query.types": "maybe_refresh",
        "state.advanced_query.neighborhoods": "maybe_refresh",
        "state.advanced_query.min_price": "maybe_refresh",
        "state.advanced_query.max_price": "maybe_refresh"
    },
    methods: {
        reroot () {
            if(!this.profile_id) return
            this.load_default_vocabularies().then(() => {
                this.ready = true
                this.reset_state(true)
            })
        },
        maybe_refresh () {
            this.data_version++
            // if(!this.data_version) this.refresh()
        },
        reset_state (force) {
            if(this.ready && (force || !this.stored_state)) {
                this.set_state_values({})
                this.set_advanced_query_state_values(
                    {
                        ...this.constants("base_listing_query"),
                        types: this.default_term_ids("VOCABULARY_PROPERTY_TYPES"),
                        neighborhoods: this.profile.neighborhood_ids || []
                    }
                )
                const end_date = this.$dayjs(new Date())
                const start_date = end_date.subtract(1, "year")
                const range = { from: start_date.format("YYYY-MM-DD"), to: end_date.format("YYYY-MM-DD") }
                this.set_advanced_query_state_values({
                    availability_changed_on: range
                })
            }
        },
        set_advanced_query_state_values (values, refresh = true) {
            const advanced_query = {
                ...this.state.advanced_query,
                ...values
            }
            this.set_state_value("advanced_query", advanced_query)
            if(refresh) this.refresh()
        },
        set_date_range (range) {
            const availability_changed_on = {
                from: this.$dayjs(range.from).format("YYYY-MM-DD"),
                to: this.$dayjs(range.to).format("YYYY-MM-DD")
            }
            this.set_advanced_query_state_values({ availability_changed_on })
        }
    },
    computed: {
        state_identifier () {
            return "realtor-dashboard-global-state"
        },
        fields () {
            return ["fieldset::dashboard"]
        },
        can_refresh () {
            return false // this.profile_id && (!!this.data_version || (!!this.state.advanced_query.availability_changed_on && !!this.state.advanced_query.types.length && (!!this.state.advanced_query.neighborhoods.length || !this.profile.neighborhood_ids.length)))
        },
        today () {
            return new Date()
        }
    },
    data () {
        return {
            entity: "listing",
            relation_ids: ["listing_showing"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "days_on_market",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                advanced_query: {},
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            center: null,
            ready: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.dashboard #dashboard-realtor {
    padding: $unit-6;

    .dashboard-panels {
        position: relative;

        .loader.main-loader {
            display: none;
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: $zindex-2;
        }

        &.active {
            .loader.main-loader {
                display: block;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.7);
                z-index: $zindex-1;
            }

        }
    }
}
</style>
