<template>
    <div v-if="is_at_least_realtor && !!statistics && !!statistics.my_listings">
        <div class="columns inventory-statistics">
            <div class="column col-2x-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <compared-statistic
                    id="my-listings"
                    :title="translate('Active Listings')"
                    :count="statistics.my_listings.count"
                    :last-count="statistics.my_listings.last_count"
                    :graph-data="listing_data(statistics.my_listings)"
                    :zoomed="zoomed"
                    :color="['tertiary', 'primary', 'light-gray', 'dark']"
                    :legend-color="['tertiary::light', 'primary', 'light-gray', 'dark']"
                    @toggle-zoom="toggle_zoom"
                >
                    <img src="@/assets/img/logo-blue.png" :alt="translate('My Active Listings')" />
                </compared-statistic>
            </div>
            <div class="column col-2x-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <compared-statistic
                    id="my-sales"
                    :title="translate('Sales (YTD)')"
                    :count="statistics.my_sales.count"
                    :last-count="statistics.my_sales.last_count"
                    :graph-data="listing_data(statistics.my_sales)"
                    :zoomed="zoomed"
                    :color="['primary', 'tertiary', 'light-gray', 'dark']"
                    :legend-color="['primary::light', 'tertiary', 'light-gray', 'dark']"
                    @toggle-zoom="toggle_zoom"
                >
                    <img src="@/assets/img/logo.png" :alt="translate('My Sales (YTD)')" />
                </compared-statistic>
            </div>
            <div class="column col-2x-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <compared-statistic
                    id="mls-listings"
                    :title="translate('Listings (MLS)')"
                    :count="statistics.mls_listings.count"
                    :last-count="statistics.mls_listings.last_count"
                    :graph-data="listing_data(statistics.mls_listings)"
                    :zoomed="zoomed"
                    :color="['tertiary', 'primary', 'light-gray', 'dark']"
                    :legend-color="['tertiary::light', 'primary', 'light-gray', 'dark']"
                    @toggle-zoom="toggle_zoom"
                >
                    <img src="@/assets/img/mls_logo_blue.jpg" :alt="translate('Listings (MLS)')" />
                </compared-statistic>
            </div>
            <div class="column col-2x-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                <compared-statistic
                    id="mls-sales"
                    :title="translate('Sales (MLS, YTD)')"
                    :count="statistics.mls_sales.count"
                    :last-count="statistics.mls_sales.last_count"
                    :graph-data="listing_data(statistics.mls_sales)"
                    :zoomed="zoomed"
                    :color="['primary', 'tertiary', 'light-gray', 'dark']"
                    :legend-color="['primary::light', 'tertiary', 'light-gray', 'dark']"
                    @toggle-zoom="toggle_zoom"
                >
                    <img src="@/assets/img/mls_logo_black.jpg" :alt="translate('Sales (MLS, YTD)')" />
                </compared-statistic>
            </div>
        </div>
        <destination-modal
            v-if="zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
    </div>
</template>

<script>
import { handles_errors } from "@/nibnut/mixins"
import { addl_profile_utilities, uses_destination_modal } from "@/custom/mixins"

import { ComparedStatistic } from "@/custom/components"

export default {
    name: "InventoryStatistics",
    mixins: [addl_profile_utilities, uses_destination_modal, handles_errors],
    components: {
        ComparedStatistic
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        advancedQuery: "refresh"
    },
    methods: {
        refresh () {
            if(!this.is_at_least_realtor) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "listing",
                    action: "dashboard",
                    method: "get",
                    data: {
                        advanced_query: this.advancedQuery
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        },
        listing_data (statistic, color = "tertiary") {
            const data = [
                {
                    title: this.translate("This Period"),
                    type: "line",
                    data: statistic.data.additions
                }
            ]
            if(this.zoomed) {
                data.push({
                    title: this.translate("Previous Period"),
                    type: "line",
                    data: statistic.data.last_additions
                })
            }
            if(this.zoomed && statistic.data.last_cumulative) {
                data.push({
                    title: statistic.data.avg ? this.translate("Previous Avg Price") : this.translate("Previous Total"),
                    type: "bar",
                    data: statistic.data.last_avg ? statistic.data.last_avg : statistic.data.last_cumulative
                })
            }
            data.push({
                title: statistic.data.avg ? this.translate("Avg Price") : this.translate("Current Total"),
                type: "bar",
                data: statistic.data.avg ? statistic.data.avg : statistic.data.cumulative
            })
            return data
        }
    },
    props: {
        advancedQuery: {
            type: Object
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>
