<template>
    <page
        :id="`dashboard-${is_at_least_realtor ? 'realtor' : 'seller'}`"
        :title="title"
        :status="page_status"
        :is-root="true"
        class="dashboard"
        @statused="statused"
    >
        <realtor-dashboard
            v-if="is_at_least_realtor"
        />
        <seller-dashboard
            v-else
        />
        <!--
        <ul class="palette">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        //-->
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import RealtorDashboard from "./RealtorDashboard"
import SellerDashboard from "./SellerDashboard"

export default {
    mixins: [is_page, addl_profile_utilities],
    components: {
        RealtorDashboard,
        SellerDashboard
    },
    mounted () {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"))
        }, 1000)
    },
    computed: {
        title () {
            if(this.is_at_least_realtor) return this.translate("Dashboard")
            return this.translate("Welcome {name}!", { name: this.profile.first_name })
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut > .nibnut-app.home {
    & > .non-navigation > main,
    .page {
        padding: 0;
    }
}
.dashboard {
    .page-content-header {
        padding: $unit-6 $unit-6 0 $unit-6;
    }
    .dashboard-panels {
        position: relative;

        .loader.main-loader {
            display: none;
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: $zindex-2;
        }

        &.active {
            .loader.main-loader {
                display: block;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.7);
                z-index: $zindex-1;
            }

        }
    }
}
</style>
