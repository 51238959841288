<template>
    <div v-if="is_at_least_realtor && !!statistics">
        <div class="columns activity-statistics">
            <div class="column col-6 col-sm-12">
                <div class="columns">
                    <div class="column col-2x-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <compared-statistic
                            id="my-price"
                            :title="translate('Price/Sale')"
                            :count="statistics.my_price_ratio.count * 100"
                            :last-count="statistics.my_price_ratio.last_count * 100"
                            glyph="chart-line"
                            color="dark"
                            :as-percentage="true"
                        >
                            <div class="logo_and_glyph">
                                <img src="@/assets/img/logo-blue.png" :alt="translate('My Price/Sale')" />
                                <open-icon
                                    glyph="chart-line"
                                    size="4x"
                                    :class="{ 'text-dark': true }"
                                />
                            </div>
                        </compared-statistic>
                    </div>
                    <div class="column col-2x-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <compared-statistic
                            id="my-dom"
                            :title="translate('D.O.M.')"
                            :count="statistics.my_days_on_market.count"
                            :last-count="statistics.my_days_on_market.last_count"
                            :inverted="true"
                            color="dark"
                        >
                            <div class="logo_and_glyph">
                                <img src="@/assets/img/logo.png" :alt="translate('My D.O.M.')" />
                                <open-icon
                                    glyph="calendar"
                                    size="4x"
                                    :class="{ 'text-dark': true }"
                                />
                            </div>
                        </compared-statistic>
                    </div>
                </div>
                <top-listings
                    :title="translate('Top Listings')"
                    :sub-title="translate('By views')"
                    :records="statistics.top_by_views"
                    class="mb-4"
                />
            </div>
            <div class="column col-6 col-sm-12">
                <div class="columns">
                    <div class="column col-2x-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <compared-statistic
                            id="mls-price"
                            :title="translate('Price/Sale')"
                            :count="statistics.mls_price_ratio.count * 100"
                            :last-count="statistics.mls_price_ratio.last_count * 100"
                            color="dark"
                            :as-percentage="true"
                        >
                            <div class="logo_and_glyph">
                                <img src="@/assets/img/mls_logo_blue.jpg" :alt="translate('MLS Price/Sale')" />
                                <open-icon
                                    glyph="chart-line"
                                    size="4x"
                                    :class="{ 'text-dark': true }"
                                />
                            </div>
                        </compared-statistic>
                    </div>
                    <div class="column col-2x-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                        <compared-statistic
                            id="mls-dom"
                            :title="translate('D.O.M.')"
                            :count="statistics.mls_days_on_market.count"
                            :last-count="statistics.mls_days_on_market.last_count"
                            :inverted="true"
                            color="dark"
                        >
                            <div class="logo_and_glyph">
                                <img src="@/assets/img/mls_logo_black.jpg" :alt="translate('MLS D.O.M.')" />
                                <open-icon
                                    glyph="calendar"
                                    size="4x"
                                    :class="{ 'text-dark': true }"
                                />
                            </div>
                        </compared-statistic>
                    </div>
                </div>
                <top-listings
                    :title="translate('Top Listings')"
                    :sub-title="translate('By showings')"
                    :records="statistics.top_by_showings"
                    class="mb-4"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { addl_profile_utilities } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"
import { TopListings, ComparedStatistic } from "@/custom/components"

export default {
    name: "ActivityStatistics",
    mixins: [addl_profile_utilities],
    components: {
        OpenIcon,
        ComparedStatistic,
        TopListings
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        advancedQuery: "refresh"
    },
    methods: {
        refresh () {
            if(!this.is_at_least_realtor) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "listing",
                    action: "activity",
                    method: "get",
                    data: {
                        advanced_query: this.advancedQuery
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    props: {
        advancedQuery: {
            type: Object
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.activity-statistics {
    align-items: stretch;

    & > .column {
        display: flex;
        flex-direction: column;
        & > div {
            flex: 0 0 auto;
            &.top-listings {
                flex: 1 1 auto;
            }
        }
    }

    .logo_and_glyph {
        position: relative;
        & > img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 16px;
            height: 16px;
        }
    }
}
</style>
