<template>
    <section>
        <statistics
            v-if="(!!listing && !!listing.quick_statistics && !!listing.quick_statistics.has_feedback_or_showings)"
            :listing="listing"
        />
    </section>
</template>

<script>
import is_seller_dashboard_section from "./IsSellerDashboardSection"

import Statistics from "../listing/Statistics"

export default {
    name: "SellerChanceOfSelling",
    mixins: [is_seller_dashboard_section],
    components: {
        Statistics
    }
}
</script>
