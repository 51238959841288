<template>
    <div class="news-container card">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Latest News") }}
            </div>
        </div>
        <div class="card-body">
            <table class="table">
                <tbody>
                    <tr
                        v-for="article in filtered_rows"
                        :key="article.published_at"
                    >
                        <td class="thumbnail">
                            <div
                                v-if="article.image_url"
                                class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
                            >
                                <div>
                                    <responsive-image
                                        :src="article.image_url"
                                        :alt="article.title"
                                    />
                                </div>
                            </div>
                            <image-placeholder
                                v-else
                                size="5x"
                            />
                        </td>
                        <td>
                            <div class="columns">
                                <a :href="article.url" class="column flex-variable h6">
                                    {{ article.title }}
                                </a>
                                <div class="column text-right text-small text-gray flex-static">
                                    {{ article.published_at | nibnut.date }}
                                </div>
                            </div>
                            <div v-html="article.content"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"

import { ResponsiveImage } from "@/nibnut/components"
import { ImagePlaceholder } from "@/custom/components"

export default {
    name: "News",
    mixins: [is_remote_data_table_source],
    components: {
        ResponsiveImage,
        ImagePlaceholder
    },
    methods: {
        post_load () {
            this.$emit("loaded", { total: this.state.total, found: this.state.found })
        }
    },
    computed: {
        state_identifier () {
            return "news"
        },
        fields () {
            return ["fieldset::default"]
        }
    },
    data () {
        return {
            entity: "blog_excerpt",
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "published_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.news-container {
    position: relative;
    height: 100%;
    width: 100%;

    .card-body {
        overflow: auto;
        height: 100%;

        td {
            vertical-align: top;

            &.thumbnail {
                width: 150px;
            }
        }
    }
}
</style>
