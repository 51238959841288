<template>
    <div v-if="!!state" v-html="state_description" class="form-label"></div>
</template>

<script>
import { addl_misc_utilities } from "@/custom/mixins"

export default {
    name: "DashboardStateDescription",
    mixins: [addl_misc_utilities],
    computed: {
        state_description () {
            const description = []
            const describe = (options, selected_ids) => {
                if(!!options && !!selected_ids && !!selected_ids.length) {
                    let selection = []
                    options.forEach(option => {
                        if(selected_ids.indexOf(option.id) >= 0) selection.push(option.name)
                        else if(!!option.sub_levels && !!option.sub_levels.length) {
                            const sub_selection = []
                            option.sub_levels.forEach(option => {
                                if(selected_ids.indexOf(option.id) >= 0) sub_selection.push(option.name)
                            })
                            if(sub_selection.length === option.sub_levels.length) selection.push(option.name)
                            else selection = selection.concat(sub_selection)
                        }
                    })
                    if(selection.length) description.push(selection.join(","))
                }
            }
            const describe_price_range = (min, max) => {
                if(!!min || !!max) {
                    if(!max) description.push(this.translate("More than {price}", { price: this.nibnut_filter("nibnut.currency", [min, "0,0"]) }))
                    else if(!min) description.push(this.translate("Less than {price}", { price: this.nibnut_filter("nibnut.currency", [max, "0,0"]) }))
                    else {
                        description.push(this.translate("{min} - {max}", { min: this.nibnut_filter("nibnut.currency", [min, "0,0"]), max: this.nibnut_filter("nibnut.currency", [max, "0,0"]) }))
                    }
                }
            }
            if(!!this.state && !!this.state.advanced_query) {
                describe(this.property_types, this.state.advanced_query.types)
                describe(this.neighborhoods, this.state.advanced_query.neighborhoods)
                describe(this.amenities, this.state.advanced_query.amenities)

                describe_price_range(this.state.advanced_query.min_price / 100, this.state.advanced_query.max_price / 100)
                describe_price_range(this.state.advanced_query.min_sale_price / 100, this.state.advanced_query.max_sale_price / 100)
            }
            return description.join(", ")
        }
    },
    props: {
        state: {
            type: Object
        }
    }
}
</script>
