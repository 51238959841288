<template>
    <div class="columns dashboard-filter-controls">
        <div v-if="!!title" class="column flex-static hide-md text-gray">
            <h3>{{ title }}</h3>
        </div>
        <div class="column flex-grow dashboard-filter-controls-container">
            <slot></slot>
        </div>
        <div v-if="!!glyph" class="column flex-static hide-md text-gray">
            <open-icon
                :glyph="glyph"
                size="2x"
            />
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    namne: "DashboardFilterControls",
    components: {
        OpenIcon
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        glyph: {
            type: String,
            default: "arrow-down"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.dashboard-filter-controls {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin: $unit-8 0;
    padding: $layout-spacing-lg;

    h3 {
        margin: 0;
    }
    @media (max-width: $size-md) {
        padding: $layout-spacing-lg $layout-spacing-sm;

        .dashboard-filter-controls-container .column {
            margin-bottom: $layout-spacing;
        }
    }
}
</style>
