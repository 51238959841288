<template>
    <section v-if="!!state.found" class="seller-feedback columns">
        <div class="column card">
            <div class="card-header">
                <div class="card-title h5">
                    {{ translate("Feedback") }}
                </div>
            </div>
            <div class="card-body">
                <div class="columns">
                    <div
                        v-for="feedback in filtered_rows"
                        :key="feedback.id"
                        class="column"
                    >
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title h5">
                                    {{ feedback.showed_at | nibnut.date }} @ {{ feedback.showed_at | nibnut.date("HH:mm") }}
                                    <feedback-rating
                                        :rating="`${feedback.rating}/5`"
                                        class="float-right"
                                    />
                                </div>
                            </div>
                            <div class="card-body">
                                {{ feedback.comments }}
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <default-button
                    color="primary"
                    size="sm"
                    :class="{ 'd-hide': state.page <= 1 }"
                    @click.stop="goto_page(state.page - 1)"
                >
                    <open-icon glyph="caret-left" />
                </default-button>
                <default-button
                    color="primary"
                    size="sm"
                    :class="{ 'd-hide': state.page >= nb_pages }"
                    @click.stop="goto_page(state.page + 1)"
                >
                    <open-icon glyph="caret-right" />
                </default-button>
                //-->
            </div>
        </div>
    </section>
</template>

<script>
import { is_remote_data_table_source } from "@/nibnut/mixins"
import is_seller_dashboard_section from "./IsSellerDashboardSection"

// import { DefaultButton, OpenIcon } from "@/nibnut/components"
import { FeedbackRating } from "@/custom/components"

export default {
    name: "SellerFeedback",
    mixins: [is_remote_data_table_source, is_seller_dashboard_section],
    components: {
        // DefaultButton,
        // OpenIcon,
        FeedbackRating
    },
    watch: {
        "listing.id": "reset",
        $mq: "reset"
    },
    methods: {
        pre_load () {
            /*
            let per_page = 6
            if(this.$mq.match(/^(xs|sm)$/i)) per_page = 1
            else if(this.$mq.match(/^(md)$/i)) per_page = 3
            else if(this.$mq.match(/^(lg)$/i)) per_page = 4
            else if(this.$mq.match(/^(xl)$/i)) per_page = 5
            */
            this.set_state_values({
                listing_id: this.listing.id
                // per_page
            })
        }
    },
    computed: {
        state_identifier () {
            return "seller-feedback"
        },
        can_refresh () {
            return this.profile_id && this.listing && this.listing.id
        },
        fields () {
            return ["fieldset::seller-dashboard"]
        }
    },
    data () {
        return {
            entity: "listing_feedback",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "published_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                advanced_query: {},
                listing_id: 0
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.seller-feedback {
    & > .card {
        & > .card-body {
            position: relative;
            & > .btn {
                position: absolute;
                left: -$unit-2;
                top: 50%;
                transform: translate(0, -50%);
                &:last-child {
                    left: auto;
                    right: -$unit-2;
                }
            }

            & > .columns {
                gap: (2 * $layout-spacing) 0;
                & > .column {
                    max-width: 100%;
                    flex: 0 0 220px;
                    .card {
                        height: 100%
                    }
                }
            }
        }
    }
}
</style>
