<template>
    <div class="card card-overflow-visible mb-4">
        <div class="card-header">
            <div class="card-title h5">
                {{ translate("Chance of Selling") }}
            </div>
        </div>
        <div class="card-body">
            <chance-of-selling-graphs
                :listing="{ id: -1 }"
                :advanced-query="advancedQuery"
            />
        </div>
    </div>
</template>

<script>
import { ChanceOfSellingGraphs } from "@/custom/components"

export default {
    name: "RealtorChanceOfSelling",
    components: {
        ChanceOfSellingGraphs
    },
    props: {
        advancedQuery: {
            type: Object
        }
    }
}
</script>
