<template>
    <div v-if="is_at_least_realtor" class="mb-4">
        <div class="card">
            <div class="card-header">
                <div class="card-title h5 px-4">
                    <div class="columns">
                        <div class="column col-3 col-lg-4 col-md-12 col-sm-12">
                            <div class="d-flex align-items-center">
                                <form-dropdown
                                    id="chart_units"
                                    name="chart_units"
                                    v-model="chart_units"
                                    :options="units"
                                    :required="true"
                                    class="flex-grow"
                                />
                                <loader
                                    v-if="loading"
                                    size="xs"
                                    color="gray"
                                    class="flex-static ml-2"
                                />
                            </div>
                            <dashboard-state-description
                                class="text-small text-gray show-md"
                                :state="globalState"
                            />
                        </div>
                        <dashboard-state-description
                            class="column col-6 col-lg-4 text-small text-center text-gray hide-md"
                            :state="globalState"
                        />
                        <div class="column col-3 col-lg-4 hide-md">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div
                    v-if="!!chart_options && !!chart_data && !chart_data[0].data.length && !chart_data[1].data.length"
                    class="empty"
                >
                    <p class="empty-title h5">{{ translate("No sales or active listings in that date range") }}</p>
                </div>
                <apexchart
                    v-else-if="!!chart_options && !!chart_data"
                    ref="chart"
                    :height="320"
                    :options="chart_options"
                    :series="chart_data"
                />
            </div>
        </div>
    </div>
</template>

<script>
// import orderBy from "lodash/orderBy"

import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import displays_chart from "@/custom/mixins/DisplaysChart"

import { FormDropdown } from "@/nibnut/components"
import { Loader } from "@/custom/components"
import DashboardStateDescription from "./DashboardStateDescription"

const color_name_to_hex = (color_name) => {
    const matches = color_name.match(/^(.+?)(?:::(light))?$/)
    color_name = matches ? matches[1] : color_name
    const light = matches ? (matches[2] === "light") : false
    let hex = "#c50802"
    switch (color_name) {
    case "secondary":
        hex = "#f4f5fa"
        break
    case "tertiary":
        hex = "#00aeef"
        break
    case "dark":
        hex = "#464855"
        break
    case "gray":
        hex = "#464855"
        break
    case "light":
        hex = "#ffffff"
        break
    case "success":
        hex = "#32b643"
        break
    case "warning":
        hex = "#ffb700"
        break
    case "error":
        hex = "#e85600"
        break
    }
    return light ? `${hex}40` : hex
}

export default {
    name: "Sales",
    mixins: [addl_profile_utilities, displays_chart],
    components: {
        FormDropdown,
        Loader,
        DashboardStateDescription
    },
    mounted () {
        const colors = [color_name_to_hex("primary"), color_name_to_hex("primary::light"), color_name_to_hex("tertiary"), color_name_to_hex("tertiary::light")]
        const options = {
            ...this.CHART_BASE_OPTIONS,
            legend: {
                show: true,
                markers: {
                    fillColors: colors
                }
            },
            colors
        }
        options.chart = {
            ...options.chart,
            id: "dashboard-sales",
            type: "area",
            zoom: {
                autoScaleYaxis: true
            }
        }
        options.markers = {
            ...this.CHART_MARKERS,
            strokeColors: colors
        }
        options.grid = {
            xaxis: {
                lines: {
                    show: false
                }
            }
        }
        options.stroke = {
            ...options.stroke,
            colors
        }

        options.xaxis = {
            // type: "datetime",
            labels: {
                formatter: (value) => {
                    return this.nibnut_filter("nibnut.date", [value, "MMM DD"])
                }
            },
            categories: []
        }
        options.yaxis = {
            ...options.yaxis,
            min: 0,
            labels: {
                formatter: (value) => {
                    if(this.chart_units === "avg") return this.nibnut_filter("nibnut.currency", [value, "0,0"])
                    return this.nibnut_filter("nibnut.number", [value, "0,0"])
                }
            }
        }
        options.tooltip.y = {
            formatter: (value) => {
                return this.nibnut_filter("nibnut.number", [value, "0,0"])
            }
        }
        options.fill = {
            type: ["solid", "solid", "solid", "solid"],
            opacity: [0.25, 0.5, 0.25, 0.5],
            colors: [color_name_to_hex("primary"), "#ffffff", color_name_to_hex("tertiary"), "#ffffff"]
        }
        this.chart_options = options
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        "globalState.advanced_query": "refresh",
        chart_units: "refresh"
    },
    methods: {
        refresh () {
            if(!this.is_at_least_realtor) return
            if(!this.globalState.advanced_query.availability_changed_on) {
                this.chart_data = null
            } else {
                this.loading = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: "listing",
                        action: "dashboard/dynamic-statistics",
                        method: "get",
                        data: {
                            chart_units: this.chart_units,
                            advanced_query: this.globalState.advanced_query
                        },
                        passthru: true
                    }
                ).then(statistics => {
                    const chart_options = { ...this.chart_options }
                    chart_options.xaxis.categories = Object.keys(statistics.my_data)
                    this.chart_options = chart_options

                    const unit = this.units.find(unit => unit.id === this.chart_units)
                    this.chart_data = [
                        {
                            name: `My ${unit.name}`,
                            // data: Object.keys(statistics.my_data).map(date => { return [this.$dayjs(date).valueOf(), statistics.my_data[date]] })
                            data: Object.values(statistics.my_data)
                        },
                        {
                            name: `My ${unit.name} (Previous Period)`,
                            type: "line",
                            // data: Object.keys(statistics.last_my_data).map(date => { return [this.$dayjs(date).valueOf(), statistics.last_my_data[date]] })
                            data: Object.values(statistics.last_my_data)
                        }
                    ]
                    if(this.chart_units !== "showings") {
                        this.chart_data.push(
                            {
                                name: `MLS ${unit.name}`,
                                // data: Object.keys(statistics.mls_data).map(date => { return [this.$dayjs(date).valueOf(), statistics.mls_data[date]] })
                                data: Object.values(statistics.mls_data)
                            }
                        )
                        this.chart_data.push(
                            {
                                name: `MLS ${unit.name} (Previous Period)`,
                                type: "line",
                                // data: Object.keys(statistics.last_mls_data).map(date => { return [this.$dayjs(date).valueOf(), statistics.last_mls_data[date]] })
                                data: Object.values(statistics.last_mls_data)
                            }
                        )
                    }
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }
        }
    },
    computed: {
        units () {
            return [
                { id: "sales", name: this.translate("Number of Sales") },
                { id: "dom", name: this.translate("Days on Market") },
                { id: "showings", name: this.translate("Number of Showings") },
                { id: "active", name: this.translate("Active listings") }
            ]
        }
    },
    props: {
        globalState: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            chart_options: null,
            chart_data: null,
            chart_units: "sales"
        }
    }
}
</script>
