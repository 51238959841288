<template>
    <div v-if="is_at_least_administrator && !!statistics">
        <div class="columns system-statistics">
            <div class="column col-3 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Users"
                    :count="statistics.active_users || 0"
                    :total="statistics.total_users || 0"
                    glyph="users"
                    color="success"
                />
            </div>
            <div class="column col-3 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Realtors"
                    :count="statistics.active_realtors || 0"
                    :total="statistics.total_realtors || 0"
                    glyph="sign"
                    color="primary"
                />
            </div>
            <div class="column col-3 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Sellers"
                    :count="statistics.active_sellers || 0"
                    :total="statistics.total_sellers || 0"
                    glyph="home"
                    color="tertiary"
                />
            </div>
            <div class="column col-3 col-md-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Buyers"
                    :count="statistics.active_buyers || 0"
                    :total="statistics.total_buyers || 0"
                    glyph="shopping-cart"
                    color="dark"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { handles_errors } from "@/nibnut/mixins"
import { addl_profile_utilities } from "@/custom/mixins"

import { CountedStatistic } from "@/custom/components"

export default {
    name: "SystemStatistics",
    mixins: [handles_errors, addl_profile_utilities],
    components: {
        CountedStatistic
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh"
    },
    methods: {
        refresh () {
            if(!this.profile_id || !this.is_at_least_administrator) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "system_statistic",
                    action: "dashboard",
                    method: "get",
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.system-statistics {
    h3 {
        margin-bottom: 0;
        .text-small {
            font-size: 0.6em;
        }
    }
    .card {
        height: 100%;
    }
    @media (max-width: $size-sm) {
        .column + .column {
            margin-top: $unit-4;
        }
    }
}
</style>
