<template>
    <section class="columns">
        <div
            :class="{ 'd-hide': !has_news }"
            class="column col-3 col-lg-4 col-md-6 col-sm-12"
        >
            <news
                @loaded="has_news = !!$event.found"
            />
        </div>
        <div
            :class="{ 'col-12': !has_news, 'col-9': has_news, 'col-lg-8': has_news, 'col-md-6': has_news }"
            class="column col-sm-12 card"
        >
            <div class="card-header">
                <div class="card-title h5">
                    {{ translate("Recent Activity") }}
                </div>
            </div>
            <div class="card-body">
                <timeline
                    v-if="!!listing"
                    :events="listing_activities(listing.id)"
                    :listing="listing"
                />
            </div>
        </div>
    </section>
</template>

<script>
import is_seller_dashboard_section from "./IsSellerDashboardSection"
import events_data_source from "@/custom/mixins/EventsDataSource"

import Timeline from "@/custom/components/Timeline"
import News from "./News"

export default {
    name: "SellerNewsAndShowings",
    mixins: [is_seller_dashboard_section, events_data_source],
    components: {
        Timeline,
        News
    },
    data () {
        return {
            has_news: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#news-showings {
    .timeline {
        max-height: 30vh;
        overflow: auto;
    }
}
</style>
