<template>
    <div v-if="is_at_least_realtor && !!statistics">
        <div class="columns">
            <div class="column col-6 col-md-6 col-sm-12 mb-4">
                <news />
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
                <div class="columns realtor-statistics">
                    <div class="column col-6 col-md-12 mb-4">
                        <counted-statistic
                            title="My Sellers"
                            :count="statistics.active_sellers || 0"
                            :total="statistics.total_sellers || 0"
                            glyph="home"
                            color="tertiary"
                        />
                    </div>
                    <div class="column col-6 col-md-12 mb-4">
                        <counted-statistic
                            title="My Buyers"
                            :count="statistics.active_buyers || 0"
                            :total="statistics.total_buyers || 0"
                            glyph="shopping-cart"
                            color="dark"
                        />
                    </div>
                    <div class="column col-12 mb-4">
                        <div class="card pull-up card-overflow-visible">
                            <div class="card-header">
                                <h6 class="text-gray">
                                    {{ translate("My Showings") }}
                                </h6>
                            </div>
                            <div
                                class="card-body"
                                @click.prevent="toggle_zoom(translate('My Showings'))"
                            >
                                <Portal to="destination-modal-destination" :disabled="!zoomed">
                                    <apexchart
                                        :height="zoomed ? 200 : 40"
                                        type="line"
                                        :options="chart_options"
                                        :series="chart_data"
                                    />
                                </Portal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column col-12 mb-4">
                <div class="card card-overflow-visible">
                    <div class="card-body">
                        <showings-list
                            :listing="{ id: 0 }"
                            :feedback-min-date="$dayjs().subtract(7, 'days')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <destination-modal
            v-if="zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
    </div>
</template>

<script>
import { string_utilities } from "@/nibnut/mixins"
import { addl_profile_utilities, uses_destination_modal } from "@/custom/mixins"

import { CountedStatistic, ShowingsList } from "@/custom/components"
import News from "./News"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "RealtorStatistics",
    mixins: [string_utilities, addl_profile_utilities, uses_destination_modal],
    components: {
        CountedStatistic,
        ShowingsList,
        News,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        "advancedQuery.availability_changed_on": "refresh",
        "advancedQuery.types": "refresh",
        "advancedQuery.neighborhoods": "refresh",
        "advancedQuery.min_price": "refresh",
        "advancedQuery.max_price": "refresh"
    },
    methods: {
        refresh () {
            if(!this.is_at_least_realtor) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "dashboard",
                    method: "get",
                    data: {
                        advanced_query: this.advancedQuery
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        }
    },
    computed: {
        chart_options () {
            return {
                chart: {
                    id: "showings",
                    height: this.zoomed ? 200 : 40,
                    type: "line",
                    fontFamily: "Quicksand, sans-serif",
                    sparkline: {
                        enabled: !this.zoomed
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: this.zoomed
                },
                stroke: {
                    width: 1
                },
                xaxis: {
                    show: this.zoomed,
                    type: "dateTime",
                    tickAmount: 12,
                    categories: Object.keys(this.statistics.showings[0].data)
                },
                yaxis: {
                    show: this.zoomed,
                    labels: {
                        formatter: (value) => {
                            return this.nibnut_filter("nibnut.number", [value, "0,0"])
                        }
                    }
                }
            }
        },
        chart_data () {
            return Object.values(this.statistics.showings).map(showings => {
                return {
                    name: showings.name,
                    data: Object.values(showings.data)
                }
            })
        }
    },
    props: {
        advancedQuery: {
            type: Object
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.realtor-statistics {
    h3 {
        margin-bottom: 0;
        .text-small {
            font-size: 0.6em;
        }
        .btn.btn-sm {
            margin-top: 0.2rem;
        }
    }
    .card {
        height: 100%;
        .apexcharts-tooltip {
            .apexcharts-tooltip-title {
                padding: 0 6px;
            }
            .apexcharts-tooltip-series-group {
                padding-bottom: 0;
                .apexcharts-tooltip-y-group {
                    padding: 2px 0;
                }
            }
        }
    }
    @media (max-width: $size-sm) {
        .column + .column {
            margin-top: $unit-4;
        }
    }
}
</style>
